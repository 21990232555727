import React, { useEffect, useState } from 'react'
import Carousel from 'nuka-carousel'
import { Button, Icon, IconButton } from 'rsuite'
import * as url from 'url'
import useWindowSize from '../../../hoc/useWindowDimensions'

import { slugify } from '../../../services/CustomerActions'
import { useSession, useSync } from '../../../utils/Storken'
import { Router } from '../../../../server/routes'


const BannerSlider = () => {
  const [city] = useSession('deliveryCity')
  const [, setLocShow] = useSync('showLocationSelect')
  const { width } = useWindowSize()
  const [up, setUp] = useState(false)

  useEffect(() => {
    if (width > 700) setUp(true)
    else setUp(false)
  }, [width])
  const openUrl = url => Router.pushRoute(url)
  return (
    <div
      className='site-intro'
    >
      <img
        src={up
          ? 'https://kgstatic.s3.eu-central-1.amazonaws.com/components/slider-desktop.jpg'
          : 'https://kgstatic.s3.eu-central-1.amazonaws.com/components/slider-mobile.jpg'}
        alt=''
      />
    </div>
  )
}

export default BannerSlider
