import App, { Container } from 'next/app'
import { NextSeo } from 'next-seo'

export default class DefaultSeo extends App {
  render() {
    return (
      <Container>
        <NextSeo
          openGraph={{
            type: 'website',
            url: 'https://www.koydengelsinn.com/',
            title: 'Köyden Gelsin',
            description: 'Doğal köy ürünleri bir tık yakınınızda!',
            site_name: 'Köyden Gelsin',
            images: [
              {
                url: 'https://svgl.s3.eu-west-3.amazonaws.com/kg-logo.jpg',
                width: 800,
                height: 600,
                alt: 'Og Image Alt'
              }
            ]
          }}
        />
      </Container>
    )
  }
}
