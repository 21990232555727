import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import 'react-multi-carousel/lib/styles.css'
import { Placeholder } from 'rsuite'

const Carousel = dynamic(() => import('react-multi-carousel'))
const { Graph } = Placeholder

const SiteBanner = () => {
  const [wait, setWait] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setWait(false)
    }, 500)
  }, [])

  return (
    <>
      {
        wait
          ? <Graph active />
          : (
            <Carousel
              autoPlay
              swipeable
              draggable
              showDots
              responsive={responsive}
              ssr // means to render carousel on server-side.
              infinite
              autoPlaySpeed={5000}
              keyBoardControl
              customTransition='all .5'
              transitionDuration={500}
              containerClass='carousel-container'
              dotListClass='custom-dot-list-style'
              itemClass='carousel-item-padding-40-px'
            >
              <img src='https://svgl.s3.eu-west-3.amazonaws.com/kargo123.jpg' alt='' />
              <img src='https://svgl.s3.eu-west-3.amazonaws.com/banner2.jpg' alt='' />
              <img src='https://svgl.s3.eu-west-3.amazonaws.com/banner3.jpg' alt='' />
            </Carousel>
          )
      }
    </>
  )
}
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
}
export default SiteBanner
